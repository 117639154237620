<template>
    <el-container class="content">
        <el-aside width="220px">
            <el-menu :default-active="activeIndex" class="menu" router @open="handleOpen" :default-openeds="defaultOpeneds" unique-opened>
                <el-submenu index="2" title>
                    <template slot="title">
                        <img src="~img/alibaba/jy.png" />
                        <span>交易管理</span>
                    </template>
                    <el-menu-item index="order_list">订单列表</el-menu-item>
                    <el-menu-item index="book_list">预订单列表</el-menu-item>
                    <el-menu-item index="cart">我的购物车</el-menu-item>
                    <el-menu-item index="collection">我的收藏夹</el-menu-item>
                    <el-menu-item index="batchOrder">批量下单</el-menu-item>
                    <el-menu-item index="batchReserveOrder">批量下预订单</el-menu-item>
                    <!--  <template v-if="is_verify == 1">
                        <el-menu-item index="verification">核销台</el-menu-item>
                        <el-menu-item index="verification_list">核销记录</el-menu-item>
                    </template> -->
                </el-submenu>
                <el-submenu index="3" title>
                    <template slot="title">
                        <img src="~img/alibaba/account.png" />
                        <span>我的账户</span>
                    </template>
                    <el-menu-item index="account">账户明细</el-menu-item>
                    <el-menu-item index="withdrawal">退货破损单</el-menu-item>
                    <el-menu-item index="payment">支付方式</el-menu-item> </el-submenu
                ><el-submenu index="1" title>
                    <template slot="title">
                        <img src="~img/alibaba/member-gray.png" />
                        <span>会员信息管理</span>
                    </template>
                    <el-menu-item index="info">会员信息</el-menu-item>
                    <el-menu-item index="delivery_address">地址管理</el-menu-item>
                    <el-menu-item index="security">密码管理</el-menu-item>
                </el-submenu>

                <el-submenu index="4" title>
                    <template slot="title">
                        <img src="~img/alibaba/msg.png" />
                        <span>留言板</span>
                    </template>
                    <el-menu-item index="message">给经理发消息</el-menu-item>
<!--                    <el-menu-item index="return_message">经理回复消息</el-menu-item>-->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
import { checkisverifier } from "@/api/order/verification"
export default {
    name: "home",
    components: {},
    data: () => {
        return {
            defaultOpeneds: ["1"],
            activeIndex: "index",
            is_verify: 1
        }
    },
    created() {
        this.activeIndex = this.$route.meta.parentRouter || this.$route.path.replace("/member/", "")
        this.checkIsVerifier()
    },
    methods: {
        handleOpen(key, keyPath) {
            this.defaultOpeneds = keyPath
        },
        checkIsVerifier() {
            checkisverifier()
                .then((res) => {
                    if (res.data) {
                        this.is_verify = 1
                    }
                })
                .catch((err) => {
                    this.is_verify = 0
                })
        }
    },
    watch: {
        $route(curr) {
            this.activeIndex = curr.meta.parentRouter || this.$route.path.replace("/member/", "")
        }
    }
}
</script>
<style lang="scss" scoped>
.menu {
    min-height: 730px;
}
.content {
    display: flex !important;
    margin: 20px 0;
    background-color: #ffffff;
}
.member {
    padding: 30px 20px;
    box-sizing: border-box;
}
</style>

<style lang="scss">
.el-aside {
    box-sizing: border-box;
    padding: 30px 15px;
    background-color: #ffffff;
    border-right: 1px solid #d9d9d9;

    ul {
        border: 0;

        > li {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .el-menu-item {
        min-width: 100px;
        margin-bottom: 0;
    }

    .el-submenu__title {
        background-color: #f2f2f2;
        font-size: 15px;
        color: $ns-text-color-black;
        font-weight: bold;

        img {
            width: 24px;
            vertical-align: middle;
            margin-right: 10px;
        }

        i {
            display: none;
        }
    }
}
</style>
